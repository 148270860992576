import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/section';
import SectionHero from '../../components/section-hero';
import CardAppPlatform from '../../components/card-app-platform';
import CardAppVizComm from '../../components/card-app-vizcomm';

const PCGPStudyPage = () => (
  <Layout className="study">
    <SEO title="Pediatric Cancer Genome Project | St. Jude Cloud" />
    <SectionHero className="study__hero" fileName="pcgp-study-hero-image.jpg">
      <Container className="study__hero__container">
        <h1>Pediatric Cancer Genome Project</h1>
      </Container>
    </SectionHero>
    <Section className="study__section">
      <Row>
        <Col>
          <h2 id="about">About the Study</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            In 2010, St. Jude Children’s Research Hospital and Washington University School of
            Medicine launched a $65 million, three-year project to define the genomic landscape of
            pediatric cancer, including some of the least understood and most challenging cancers.
            The project goal was to sequence the complete normal and cancer genomes of 600 pediatric
            cancer patients. The St. Jude—Washington University Pediatric Cancer Genome Project
            became an ambitious effort to discover the origins of pediatric cancer and seek new
            treatments. The project ultimately sequenced the complete and normal genomes of about
            800 pediatric cancer patients. It included whole exome and whole transcriptome
            sequencing of an additional 1,200 patients, which included more than 20 different
            cancers.
          </p>
        </Col>
      </Row>
    </Section>
    <Section gray>
      <Row>
        <Col>
          <h2 id="apps">Apps</h2>
          <p>
            Data from the Pediatric Cancer Genome Project study is included in the following St.
            Jude Cloud applications.
          </p>
        </Col>
      </Row>
      <Row className="study__apps">
        <CardAppPlatform
          lg={6}
          description="Next-generation sequencing data from the Pediatric Cancer Genome Project is available to analyze in the cloud."
          url="https://platform.stjude.cloud/data/cohorts?dataset_accession=SJC-DS-1001"
        />
        <CardAppVizComm
          lg={6}
          description="Multiple visualizations have been developed to accompany papers published as part of the Pediatric Cancer Genome Project."
          url="https://viz.stjude.cloud/visualizations?research-domain=pediatric-cancer"
        />
      </Row>
    </Section>
    <Section className="study__section--citation">
      <Row>
        <Col>
          <h2 id="citation">Cite the Program</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Please visit{' '}
            <a href="https://university.stjude.cloud/docs/citing-stjude-cloud/">
              our dedicated documentation page on how to cite St. Jude Cloud
            </a>{' '}
            when publishing manuscripts utilizing data or information from this resource.
          </p>
          <p>
            Questions or Feedback? Contact us at{' '}
            <a href="mailto:support@stjude.cloud">support@stjude.cloud</a>.
          </p>
        </Col>
      </Row>
    </Section>
  </Layout>
);
export default PCGPStudyPage;
